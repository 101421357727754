import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui"
import Tippy from "@tippyjs/react/headless"

import Box from "./box"
import { alpha } from "@theme-ui/color"

const Tooltip = (props) => {
  const { children, content } = props

  const context = useThemeUI()
  const { theme, colorMode } = context

  const bgColor = colorMode === "light" ? "black" : "white"

  return (
    <Tippy
      trigger="mouseenter click focus"
      render={(attrs) => (
        <Box
          bg={alpha(bgColor, 0.95)}
          color={colorMode === "light" ? "white" : "black"}
          maxWidth="300px"
          p={3}
          borderRadius="md"
          tabIndex="-1"
          {...attrs}
        >
          {content}
        </Box>
      )}
    >
      <Box
        as="button"
        type="button"
        cursor="default"
        appearance="none"
        bg="transparent"
        display="inline-block"
        textDecoration={`underline dotted ${theme.colors.primary}`}
        sx={{
          fontStyle: "inherit",
          ":hover, :focus": {
            textDecoration: "none",
          },
        }}
      >
        {children}
      </Box>
    </Tippy>
  )
}

export default Tooltip
