import React from "react"
import PropTypes from "prop-types"
import Poem from "../../../../components/poem"
import Abbreviation from "../../../../components/abbreviation"

const PoemFruehlingExplained = ({ size }) => {
  return (
    <Poem size={size}>
      <p>
        <strong>Der Frühling.</strong>
      </p>
      <br />
      <p>Wenn aus der Tiefe kommt der Frühling in das Leben,</p>
      <p>
        Es wundert sich der Mensch, und neue Worte{" "}
        <Abbreviation title="versuchen, etwas zu bekommen">
          streben
        </Abbreviation>
      </p>
      <p>
        Aus{" "}
        <Abbreviation title="Gedanklich; etwas, was sich nur denken lässt">
          Geistigkeit
        </Abbreviation>
        , die Freude{" "}
        <Abbreviation title="zurückkommen">kehret wieder</Abbreviation>
      </p>
      <p>
        Und <Abbreviation title="feierlich">festlich</Abbreviation> machen sich
        Gesang und Lieder.
      </p>
      <br />
      <p>
        Das Leben findet sich aus{" "}
        <Abbreviation title="Ausgewogenheit">Harmonie</Abbreviation> der Zeiten,
      </p>
      <p>
        Daß <Abbreviation title="jederzeit">immerdar</Abbreviation> den{" "}
        <Abbreviation title="die Wahrnehmung">Sinn</Abbreviation> Natur und{" "}
        <Abbreviation title="Verstand">Geist</Abbreviation>{" "}
        <Abbreviation title="begleiten">geleiten</Abbreviation>,
      </p>
      <p>
        Und die Vollkommenheit ist Eines in dem{" "}
        <Abbreviation title="Verstand">Geiste</Abbreviation>,
      </p>
      <p>So findet vieles sich, und aus Natur das Meiste.</p>
      <br />
      <p>d. 24 Mai 1758.</p>
      <br />
      <p>Mit Unterthänigkeit</p>
      <p>Scardanelli.</p>
    </Poem>
  )
}

PoemFruehlingExplained.propTypes = {
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  space: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
}

PoemFruehlingExplained.defaultProps = {
  size: [2, 3],
}

export default PoemFruehlingExplained
