import PropTypes from "prop-types"
import React from "react"
import { useThemeUI } from "theme-ui"
import Tooltip from "./tooltip"
import Box from "./box"

const Abbreviation = ({ title, children }) => {
  const context = useThemeUI()
  const { theme } = context

  return (
    <Tooltip content={title}>
      <Box
        as="abbr"
        sx={{
          textDecoration: "underline",
          textDecorationLine: "underline",
          textDecorationStyle: "dotted",
          textDecorationColor: theme.colors.whisper,
        }}
      >
        {children}
      </Box>
    </Tooltip>
  )
}

Abbreviation.propTypes = {
  title: PropTypes.string,
}

export default Abbreviation
